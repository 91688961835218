import fetch from 'isomorphic-unfetch'
import * as queryString from 'query-string';
import axios from 'axios'
import { convertState } from '../utils/convert-state';

const TO_NAME = 1;
const TO_CODE = 2;

async function getLocationList(options) {
    const defaultOptions = {
        query: '',
        type: 'city',
        countries: 'us',
        aroundLatLngViaIP: true,
        language: 'en',
        hitsPerPage: 10
    }
    const body = {...defaultOptions, ...options};

    const url = 'https://places-dsn.algolia.net/1/places/query';
    return axios.post(url, body, {
        headers: {
            'X-Algolia-Application-Id': process.env.ALGOLIA_PLACES_APP_ID,
            'X-Algolia-API-Key': process.env.ALGOLIA_PLACES_APP_SECRET
        }
    })
}

const getLocationByObjectId = async (objectId) => {
    const resp = await axios.get(`https://places-dsn.algolia.net/1/places/${objectId}`);
    const location = {
        city: resp.data.locale_names['default'][0],
        state_name: resp.data.administrative[0],
        state_code: convertState(resp.data.administrative[0], TO_CODE),
        _geoloc: resp.data._geoloc,
        objectID: resp.data.objectID
    }
    return Promise.resolve(location);
}

const getReverseGeocoding = async (_geoLoc) => {
    const params = {
        aroundLatLng: `${_geoLoc.latitude},${_geoLoc.longitude}`,
        language: 'us',
        hitsPerPage: 1
    }
    const resp = await getReverseGeoCoding(params);
    const result = await resp.json();
    const userBrowserLocation = result.hits[0];

    const location = {
        city: userBrowserLocation.city[0],
        _geoloc: userBrowserLocation._geoloc,
        state_name: userBrowserLocation.administrative[0],
        state_code: convertState(userBrowserLocation.administrative[0], TO_CODE),
        objectID: userBrowserLocation.objectID
    }
    dispatch({ type: 'SET_LOCATION', payload: location })
}

async function getReverseGeoCoding(params) {
    const url = `https://places-dsn.algolia.net/1/places/reverse?${queryString.stringify(params)}`;
    const response = await fetch(url, {
        method: "GET",
    });
    return response;
}

function mapLocation(locationList) {
    const locations = [];
    locationList.forEach(x => {
        const location = {
            city: x.locale_names[0],
            state_name: x.administrative[0],
            state_code: convertState(x.administrative[0], TO_CODE),
            text: `${x.locale_names[0]}, ${convertState(x.administrative[0], TO_CODE)}`,
            slug: `${x.locale_names[0]}, ${convertState(x.administrative[0], TO_CODE)}`.replace(/,*\s/g, '-').toLowerCase(),
            _geoloc: x._geoloc,
            lat: x._geoloc.lat,
            lng: x._geoloc.lng,
            objectID: x.objectID
        }
        locations.push(location)
    })
    return locations;
}


export {
    getLocationList,
    getReverseGeoCoding,
    mapLocation,
    getLocationByObjectId
}

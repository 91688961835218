import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import { getLocationList, mapLocation } from '../services/location-service';
import { useLocation } from '../hooks/location';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faMapMarkerAlt } from '@fortawesome/pro-duotone-svg-icons'


export default function LocationSelection(props) {
    const { currentLocation, setLocation } = useLocation({ initialLocation: props.initialLocation });
    const [popupOpen, setPopupOpen] = useState(false);
    const [locationList, setLocationList] = useState([]);

    const onSelect = (e) => {
        const location = locationList[e.currentTarget.dataset.index];
        setLocation(location);
        setPopupOpen(false);
    }

    const search = async (e) => {
        const options = {
            query: e.target.value
        }
        getLocationList(options).then(resp => {
            if (resp.status === 200) {
                const locations = mapLocation(resp.data.hits);
                setLocationList(locations);
            }
        });
    }

    const renderLocationItem = locationList.map((x, i) =>
        <li className="mx-3"
            key={x.objectID} data-index={i} onClick={onSelect}>
            <div className="flex items-center w-full px-3 py-2 cursor-pointer hover:bg-blue-500 hover:text-white rounded-sm">
                <span className="mr-2">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                </span>
                <span>{x.city}, {x.state_code}</span>
            </div>
        </li>
    );

    const renderContent = () => {
        return (
            <div className="flex flex-col h-full">
                <div className="p-4">
                    <Input placeholder="Enter zipcode, city" onChange={search} />
                </div>
                <ul className="overflow-y-auto flex-1">
                    {renderLocationItem}
                </ul>
                <div>
                    <button onClick={() => setPopupOpen(false)} className="m-4 text-sm whitespace-no-wrap px-5 py-2 leading-none border font-medium rounded bg-gray-300 text-gray-600 hover:text-gray-600 hover:bg-gray-400">
                        Close
                    </button>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <span onClick={() => setPopupOpen(true)} className="text-left text-white lg:text-indigo-800 lg:hover:text-white text-md cursor-pointer border-2 border-indigo-800 border-solid hover:bg-indigo-700 px-4 py-2 rounded-md">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                <span>{currentLocation && `${currentLocation.city}, ${currentLocation.state_code}`}</span>
                <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
            </span>
            <Modal
                title="Location lookup"
                visible={popupOpen}
                onOk={() => setPopupOpen(false)}
                onCancel={() => setPopupOpen(false)}
                footer={null}
                width="400px"
                bodyStyle={{ padding: '0px', height: '500px' }}
            >
                {renderContent()}
            </Modal>
        </React.Fragment>
    )
}

import useSWR, { mutate } from 'swr'
import axios from '../lib/axios'

export const useLocation = ({ initialLocation }) => {


    const { data: currentLocation, error } = useSWR('get-location', () =>
        axios
            .get('/api/v1/locations/get-location')
            .then(res => res.data)
            .catch(error => {
                if (error.response.status != 409) throw error
            }),
        {
            initialData: initialLocation
        }
    )

    const setLocation = (location) => {
        axios.post('/api/v1/locations/set-location', location)
            .then(() => mutate('get-location'))
    }

    const searchLocation = (options) => {
        const defaultOptions = {
            query: '',
            type: 'city',
            countries: 'us',
            aroundLatLngViaIP: true,
            language: 'en',
            hitsPerPage: 10
        }

        return axios.post('https://places-dsn.algolia.net/1/places/query', { ...defaultOptions, ...options }, {
            headers: {
                'X-Algolia-Application-Id': process.env.ALGOLIA_PLACES_APP_ID,
                'X-Algolia-API-Key': process.env.ALGOLIA_PLACES_APP_SECRET
            }
        })
    }

    // STATES

    const { data: states, getStatesError } = useSWR('/api/v1/states', () =>
        axios
            .get('/api/v1/states')
            .then(res => res.data.data)
            .catch(error => {
                if (error.response.status != 409) throw error
            }),
        {
            revalidateOnFocus: false
        }
    )

    return {
        currentLocation,
        setLocation,
        searchLocation,
        states
    }
}